import { Button, Card, makeStyles, Typography } from '@material-ui/core'
import { navigate } from 'gatsby'
import React from 'react'


const useStyles = makeStyles({
    root: {
        minHeight:'160px',
        minWidth:'160px',
        textAlign:'center',
        padding: '1rem',
        margin:'auto',
        backgroundColor:'#434789',
        opacity: 0.9
    },
    title: {
        fontSize: 50,
        color:'#FFFFFF',
        marginBottom:'1rem'
    },
    infotext: {
        color:'#FFFFFF'
    },
    button: {
        marginTop: '1rem',
        color: '#FFFFFF',
        backgroundColor: '#f26718'
    }
})


const BackgroundSectionCard = () => {

    const classes = useStyles()


    return <Card className={classes.root}>
        <Typography className={classes.title}>Melhus Minilager</Typography>
        <Typography className={classes.infotext}>Minilager i Trondheimsområdet til markedsdyktige priser</Typography>
        <Button className={classes.button} onClick={() => navigate('/order')}>Bestill nå</Button>
    </Card>
}

export default BackgroundSectionCard