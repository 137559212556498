import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {Card, makeStyles} from '@material-ui/core'
import BackgroundImage from 'gatsby-background-image'
import BackgroundSectionCard from './backgroundSectionCard'

const useStyles = makeStyles({
    root: {
      height:'100vh',
      backgroundPosition: 'center',
      backgroundRepeat: 'repeat-y',
      backgroundSize: 'cover',
      backgroundAttachment: 'fixed',
      alignItems:'center',
      justifyContent:'center',
      display:'flex'
    },
    content:{
        margin:"auto"
    }
  }
  )

  const BackgroundSection = () => {

    const data = useStaticQuery(
      graphql`
      query {
        desktop: file(relativePath: {eq: "schive/bilde-1.JPG"}){
          childImageSharp {
            fluid(quality:90, maxWidth: 1920){
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      `
    )
    const imageData = data.desktop.childImageSharp.fluid

    const classes = useStyles()

    return (
        <BackgroundImage
          Tag="section"
          fluid={imageData}
          backgroundColor={`#040e18`}
          className={classes.root}
        >
          <BackgroundSectionCard/>
        </BackgroundImage>
    )
  }

  export default BackgroundSection