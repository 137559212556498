import { Grid, Paper, Typography } from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles'
import React from 'react'
import ProductCard from './ProductCard'

const products = [
    {size:'XS',cost:'359', minArea: '2,6', minVolume: '6,5'},
    {size:'S', cost:'550', minArea: '4', minVolume: '10'},
    {size:'M', cost: '770', minArea: '5,5', minVolume: '14'},
    {size:'L', cost: '1320', minArea: '8,8', minVolume: '22'}
]

const useStyles = makeStyles({
    root:{
        backgroundColor: "white",
        minHeight:"10rem",
        minWidth:"100%",
        padding: "3rem",
        justifyContent: 'center',
        textAlign: 'center'
    },
    grid:{
        minWidth: '100%'
    },
    title:{
        fontSize: 50,
        marginBottom: '3rem'
    },
    item:{
    }
})

const ProductDisplay = () => {

    const classes = useStyles()

    const DisplayProducts = () => {
        return products.map((product) => {
            return <Grid xs={12} md={3} item className="classes.item" key={product.size}>
                <ProductCard {...product}/>
                </Grid>
        })
    }

    return (
    <div className={classes.root}>
        <Typography className={classes.title}>Våre Minilager</Typography>
    <Grid className={classes.grid} container justify="space-around" spacing={4}>
        {DisplayProducts()}
    </Grid>
    </div>)
}

export default ProductDisplay