import React from 'react'
import { Button, Card, Typography, useTheme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Link from './Link'
import { navigate } from 'gatsby'

const useStyles = makeStyles({
    root: {
        minWidth: 160,
        maxWidth: 300,
        textAlign:"center",
        backgroundColor: "#F26718",
        paddingTop: "1rem",
        margin: "auto"
    },
    title: {
        fontSize : 50,
        color: "white"
    },
    cost: {
        color: "white",
        marginBottom:'1rem'
    },
    button: {
        marginBottom: "1rem"
    }
})


interface ProductCardProps{
    size: string,
    cost: string,
    minArea: string,
    minVolume: string
}

const ProductCard = (props: ProductCardProps) => {
    const classes = useStyles()
    const theme = useTheme()

    return <Card className={classes.root}>
        <Typography className={classes.title} gutterBottom>
        {props.size}
        </Typography>
        <Typography className={classes.cost}>
             Størrelse fra {props.minArea}m<sup>2</sup> ({props.minVolume}m<sup>3</sup>)
        </Typography>
        <Typography className={classes.cost}>
            Pris fra {props.cost},-
        </Typography>
        <Button variant='contained' color='secondary' className={classes.button} onClick={() => navigate('/order',{state: {choice: props.size}})}>
            <Typography>Bestill</Typography>
        </Button>
        </Card>
}

export default ProductCard