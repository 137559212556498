import React from "react"
import { Link } from "gatsby"
import Map from "../components/Map"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import ProductDisplay from "../components/productDisplay"
import { makeStyles } from "@material-ui/core"
import BackgroundSection from "../components/backgroundSection"



const IndexPage = () => {

  return (
  <Layout>
    <SEO title="Hjem" />
    <BackgroundSection/>
    <ProductDisplay/>
  </Layout>)
}

export default IndexPage
